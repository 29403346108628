import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions
} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import {styles} from './styles';
import RenderCartHeader from './renderCartHeader';
import ToteRowItemsControllers from './toteRowItemsControllers';
import I18n from 'i18n-js';
import {useDispatch, useSelector} from 'react-redux';
import {
  addCart,
  fetchCarts,
  updateCart,
  updateCartDataLocal
} from '../../actions/scanToCartActions';
import { ActivityIndicator } from 'react-native-web';

const ScanToCartSettings = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get('window').width
  );
  const [editingCartId, setEditingCartId] = useState(null);
  const carts = useSelector(state => state.scanToCart.carts);

  useEffect(() => {
    const updateWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateWidth);
    return () => {
      if (Dimensions.removeEventListener) {
        Dimensions.removeEventListener('change', updateWidth);
      }
    };
  }, []);

  const callBack = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    dispatch(fetchCarts(callBack));
  }, []);

  const getNextCartId = () => {
    const nextNum = carts.length + 1;
    return `C${nextNum.toString().padStart(2, '0')}`;
  };

  const getNextRowId = rows => {
    if (rows.length === 0) return 'A';
    const lastRow = rows[rows.length - 1].id;
    if (lastRow.length === 1 && lastRow >= 'A' && lastRow <= 'Z') {
      return String.fromCharCode(lastRow.charCodeAt(0) + 1);
    }
    return String.fromCharCode('A'.charCodeAt(0) + rows.length);
  };

  const deleteRow = (cartId, rowIndex) => {
    const cart = carts.find(c => c.id === cartId);
    if (cart) {
      const updatedCart = {
        ...cart,
        cart_rows: cart.cart_rows.filter((_, index) => index !== rowIndex)
      };
      dispatch(updateCartDataLocal(updatedCart));
      setEditingCartId(cartId);
    }
  };

  const addNewCart = () => {
    const newCart = {
      cart_id: getNextCartId(),
      cart_name: `Cart ${carts.length + 1}`,
      number_of_totes: 5,
      cart_rows: [
        {
          row_name: 'A',
          row_count: 5
        }
      ]
    };

    dispatch(addCart(newCart));
  };

  const addRow = cartId => {
    const cart = carts.find(c => c.id === cartId);
    if (cart) {
      const updatedCart = {
        ...cart,
        cart_rows: [
          ...cart.cart_rows,
          {
            cart_id: cartId,
            row_name: getNextRowId(cart.cart_rows),
            row_count: 5
          }
        ]
      };
      dispatch(updateCartDataLocal(updatedCart));
      setEditingCartId(cartId);
    }
  };

  const updateRowName = (cartId, rowIndex, newName) => {
    const cart = carts.find(c => c.id === cartId);
    if (cart) {
      const updatedCart = {
        ...cart,
        cart_rows: cart.cart_rows.map((row, index) =>
          index === rowIndex ? {...row, row_name: newName} : row
        )
      };
      dispatch(updateCartDataLocal(updatedCart));
      setEditingCartId(cartId);
    }
  };

  const updateToteCount = (cartId, rowIndex, newValue) => {
    const numValue = parseInt(newValue, 10);
    if (isNaN(numValue)) return;

    const cart = carts.find(c => c.id === cartId);
    if (cart) {
      const newTotes = Math.max(1, Math.min(10, numValue));
      const updatedCart = {
        ...cart,
        cart_rows: cart.cart_rows.map((row, index) =>
          index === rowIndex ? {...row, row_count: newTotes} : row
        )
      };
      dispatch(updateCartDataLocal(updatedCart));
      setEditingCartId(cartId);
    }
  };

  const handleCartUpdate = cartId => {
    const cart = carts.find(c => c.id === cartId);
    if (cart) {
      dispatch(updateCart(cartId, cart));
      setEditingCartId(null);
    }
  };

  const copyToteId = async (cartId, rowId, toteNumber) => {
    const barcodeValue = `${rowId}-${toteNumber}-${cartId}`;
    await Clipboard.setStringAsync(barcodeValue);
  };

  const renderTote = ({item: toteNumber, rowId, cartId, totalTotes}) => {
    const availableWidth = screenWidth * 0.68 - 280;
    const toteWidth = Math.max(availableWidth / totalTotes - 10, 60);
    return (
      <TouchableOpacity
        testID="copyToteButtonId"
        style={[styles.tote, {width: toteWidth, maxWidth: toteWidth}]}
        onPress={() => copyToteId(cartId, rowId, toteNumber)}>
        <Text
          numberOfLines={1}
          style={[styles.toteText, {fontSize: screenWidth < 768 ? 12 : 14}]}>
          {`${rowId}-${toteNumber}`}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderRow = ({item: row, index, cartId}) => {
    const totesToRender = screenWidth < 900 ? 1 : row.row_count;
    return (
      <View style={styles.rowContainer}>
        <TouchableOpacity
          testID="deleteCartRowButton"
          style={styles.deleteButton}
          onPress={() => deleteRow(row.cart_id, index)}>
          <i className=" icon-cancel" style={{fontSize: 24, color: '#ffff'}} />
        </TouchableOpacity>

        <TextInput
          testID="rowIdInput"
          style={styles.rowInput}
          autoFocus={true}
          value={row.row_name}
          onChangeText={newName => updateRowName(row.cart_id, index, newName)}
          autoCapitalize="characters"
        />

        <View style={styles.toteListContainer}>
          {[...Array(totesToRender)].map((_, i) => (
            <View key={`${row.id}-${i + 1}`}>
              {renderTote({
                item: i + 1,
                rowId: row.row_name,
                cartId,
                totalTotes: row.row_count
              })}
            </View>
          ))}
        </View>

        <ToteRowItemsControllers
          initialValue={row.row_count}
          onUpdate={newValue => updateToteCount(row.cart_id, index, newValue)}
        />
      </View>
    );
  };
  const renderCart = ({item: cart}) => (
    <View style={styles.cartContainer}>
      <RenderCartHeader
        name={cart?.cart_name}
        screenWidth={screenWidth}
        id={cart.id}
        cartId={cart.cart_id}
      />

      <FlatList
        data={cart.cart_rows}
        renderItem={({item, index}) =>
          renderRow({item, index, cartId: cart.cart_id})
        }
        keyExtractor={(item, index) => `${cart.id}-${item.id}-${index}`}
      />

      {editingCartId !== cart.id ? (
        <TouchableOpacity
          testID="addCartRowButton"
          style={styles.addRowButton}
          onPress={() => addRow(cart.id)}>
          <i className="icon-plus" style={{color: '#ffff', fontSize: 18}}></i>
          <Text
            style={[
              styles.addRowText,
              {fontSize: screenWidth < 768 ? 12 : 14}
            ]}>
            {I18n.t('scanToCart.addRowLabel')}
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          testID="saveChanges"
          style={styles.addRowButton}
          onPress={() => handleCartUpdate(editingCartId)}>
          <Text
            style={[
              styles.addRowText,
              {fontSize: screenWidth < 768 ? 12 : 14}
            ]}>
            {I18n.t('scanToCart.saveChanges')}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );

  return (
    <View style={[styles.container, {padding: screenWidth < 768 ? 10 : 18}]}>
      <Text style={[styles.header, {fontSize: screenWidth < 768 ? 20 : 24}]}>
        {I18n.t('scanToCart.cartsLabel')}
      </Text>
      <View style={styles.cardsContainer}>
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <FlatList
            data={carts}
            renderItem={renderCart}
            keyExtractor={item => item.id}
          />
        )}
        <TouchableOpacity
          testID="addCartButton"
          style={styles.addCartButton}
          onPress={addNewCart}>
          <i className="icon-plus" style={{color: '#ffff', fontSize: 25}}></i>
          <Text
            style={[
              styles.addCartText,
              {fontSize: screenWidth < 768 ? 12 : 14}
            ]}>
            {I18n.t('scanToCart.addCartLabel')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ScanToCartSettings;
