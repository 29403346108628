import axios from 'axios';
import { ADD_CART, FETCH_CARTS, UPDATE_CART, UPDATE_CART_LOCAL } from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function fetchCarts() {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.get(`${url}/carts.json`, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: FETCH_CARTS,
        payload: response.data
      });
    } catch (error) {
      console.error('Error fetching carts:', error);
    }
  };
}
export function printToteLabels(cartId, callBack) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios({
        method: 'get',
        url: `${url}/carts/${cartId}/print_tote_labels.pdf`,
        headers: {
          Authorization: `Bearer ${access_token}`
        },
        responseType: 'blob'
      });
      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl);
      
      if (callBack) {
        callBack(true);
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
      if (callBack) {
        callBack(false);
      }
    }
  };
}

export function addCart(cartData) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.post(`${url}/carts.json`, cartData, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: ADD_CART,
        payload: response.data
      });
    } catch (error) {
      console.error('Error creating carts:', error);
    }
  };
}

export function updateCart(cartId, cartData) {
  return async dispatch => {
    try {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      const response = await axios.patch(`${url}/carts/${cartId}.json`, cartData, {
        headers: {Authorization: `Bearer ${access_token}`}
      });
      dispatch({
        type: UPDATE_CART,
        payload: response.data
      });
    } catch (error) {
      console.error('Error updating cart:', error);
    }
  };
}
export function updateCartDataLocal(cartData) {
  return dispatch => {
    dispatch({
      type: UPDATE_CART_LOCAL,
      payload: cartData
    });
  };
}
