import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    borderColor: '#687C90',
    borderWidth: 2,
    borderRadius: 25,
    position: 'relative',
  },
  header: {
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginBottom: '3%',
    marginLeft: 10
  },
  cardsContainer: {
    flex: 1,
  },
  cartContainer: {
    borderRadius: 15,
    padding: 15,
    marginBottom: 20,
    borderColor: '#687C90',
    borderWidth: 2,
    position: 'relative',
    zIndex: 1,
  },
  cartHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    flexWrap: 'wrap',
    paddingHorizontal: 15,
  },
  cartTitle: {
    fontFamily: 'Poppins_600SemiBold',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  printButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    borderRadius: 5,
  },
  printButtonText: {
    fontFamily: 'Poppins_300Light',
    color: '#FFFFFF',
    marginLeft: 5,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: 2,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
    flexWrap: 'wrap',
  },
  deleteButton: {
    padding: 5,
    width: 40,
    alignItems: 'center',
  },
  rowInput: {
    backgroundColor: '#FFFFFF',
    width: 80,
    height: 40,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginHorizontal: 10,
    textAlign: 'center',
    fontSize: 16,
  },
  toteListContainer: {
    flex: 1,
    flexDirection: 'row',
    marginRight: 10,
    marginTop: 5
  },
  tote: {
    height: 40,
    marginRight: 10,
    marginBottom: 5,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#FFFFFF',
  },
  toteText: {
    fontFamily: 'Poppins_600SemiBold',
    color: '#FFFFFF',
  },
  toteCountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 80,
  },
  toteCountInput: {
    backgroundColor: '#FFFFFF',
    width: 35,
    height: 40,
    borderRadius: 5,
    textAlign: 'center',
    fontSize: 16,
  },
  countButton: {
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addRowButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderRadius: 5,
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    marginHorizontal: 'auto',
    justifyContent: 'center',
  },
  addRowText: {
    fontFamily: 'Poppins_300Light',
    color: '#FFFFFF',
    marginLeft: 5,
  },
  addCartButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderRadius: 5,
    alignSelf: 'center',
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#FFFFFF',
  },
  addCartText: {
    fontFamily: 'Poppins_300Light',
    color: '#FFFFFF',
    marginLeft: 5,
  },
  headerLeft: {
    fontFamily: 'Poppins_300Light',
    marginLeft: 45,
  },
  headerRight: {
    fontFamily: 'Poppins_300Light',
    marginRight: 20,
  },
  headerText: {
    fontFamily: 'Poppins_300Light',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: '500',
  },
});
