import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import dropdownStyles from '../../style/dropdownStyles';
import usePersistentMenuState from '../../commons/usePersistentMenuState';

const ToggleButton = ({value, onToggle, style, testID}) => {
  return (
    <TouchableOpacity 
      style={[dropdownStyles.toggleButton, style]} 
      onPress={onToggle}
      testID={testID}>
      <Text style={dropdownStyles.toggleButtonText}>{value}</Text>
    </TouchableOpacity>
  );
};

const CustomTouchableOpacity = ({
  onPress,
  handleStatusSelect,
  buttonName,
  buttonText,
  orderCount,
  testID
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={dropdownStyles.customTouchable}
      testID={testID}>
      <View style={dropdownStyles.customTouchableContent}>
        <Text style={dropdownStyles.customTouchableCount}>{orderCount}</Text>
        <Text
          style={[
            Array.isArray(handleStatusSelect) && handleStatusSelect.includes(buttonName) &&
              dropdownStyles.dropdownActiveButton,
            dropdownStyles.customTouchableText
          ]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity
          onPress={onPress}
          style={dropdownStyles.customTouchableReset}
          testID={testID + '_reset'}>
          {Array.isArray(handleStatusSelect) && handleStatusSelect.includes(buttonName) && (
            <i
              className="icon-cancel"
              style={dropdownStyles.customTouchableIcon}></i>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const ShowAllButton = ({onPress, showMore}) => (
  <TouchableOpacity style={dropdownStyles.showAllButton} onPress={onPress}>
    <Text style={dropdownStyles.showAllButtonText}>
      {showMore ? 'Show All' : 'Show Less'}
    </Text>
  </TouchableOpacity>
);

const OrderTagsDropdown = ({
  includedTags,
  excludedTags,
  clearAllFilters,
  resetFilterCheck,
  status,
  setSelectedTags,
  setFilterIncludedTags,
  filterIncludedTags,
  matchOptionFlag,
  setMatchOptionFlag,
}) => {
  const [isOpen, toggleDropdown] = usePersistentMenuState('orderTagsDropdown');
  const [includeOption, setIncludeOption] = useState(true);
  const [matchOption, setMatchOption] = useState(matchOptionFlag === 'All' ? true : false);

  const normalizeStatus = (inputStatus) => {
    if (!inputStatus) return [];
    if (Array.isArray(inputStatus)) {
      if (inputStatus.length > 0 && Array.isArray(inputStatus[0])) {
        return inputStatus[0];
      }
      return inputStatus;
    }
    return [inputStatus];
  };
  
  const [selectedStatus, setSelectedStatus] = useState(normalizeStatus(status));
  const [showAllTags, setShowAllTags] = useState(false);

  const toggleIncludeOption = () => {
    if (selectedStatus.length > 0) {
      setSelectedStatus([]);
      setSelectedTags([]);
    }

    setIncludeOption(!includeOption);
    setFilterIncludedTags(!includeOption);
  };

  const toggleMatchOption = () => {
    setMatchOption(!matchOption);
    setMatchOptionFlag(!matchOption ? 'All' : 'Any');
  };

  const handleStatusSelect = tag => {
    let selectedTags = [...selectedStatus];
    if (!selectedTags.includes(tag)) {
      selectedTags.push(tag);
    } else {
      selectedTags = selectedTags.filter(item => item !== tag);
    }
    setSelectedStatus(selectedTags);
    setSelectedTags(selectedTags);
  };

  const toggleShowAllTags = () => {
    setShowAllTags(prevShowAllTags => !prevShowAllTags);
  };

  useEffect(() => {
    if (clearAllFilters === true) {
      setSelectedStatus([]);
      resetFilterCheck();
    }
  }, [clearAllFilters, resetFilterCheck]);

  useEffect(() => {
    if (status) {
      const normalizedStatus = normalizeStatus(status);
      if (JSON.stringify(normalizedStatus) !== JSON.stringify(selectedStatus)) {
        setSelectedStatus(normalizedStatus);
      }
    }
  }, [status]);

  const tagsToDisplay = includeOption ? includedTags : excludedTags;
  const countProperty = includeOption ? 'order_count' : 'not_present_in_order_count';

  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)'}}>
      <TouchableOpacity style={dropdownStyles.button} onPress={toggleDropdown}>
        <View style={dropdownStyles.buttonContent}>
          <Text style={dropdownStyles.text}>Tags</Text>
          <Image
            style={[dropdownStyles.icon, isOpen && dropdownStyles.iconOpen]}
            source={downArrow}
          />
        </View>
      </TouchableOpacity>
      {isOpen && (
        <View style={[dropdownStyles.toggleContainer,{backgroundColor: '#262B30', paddingBottom: 2}]}>
          <Text style={dropdownStyles.toggleLabel}>Orders</Text>
          <ToggleButton 
            value={includeOption ? "Include" : "Do not include"} 
            onToggle={toggleIncludeOption} 
            style={dropdownStyles.includeToggle}
            testID="include_toggle_button"
          />
          <ToggleButton 
            value={matchOption ? "All" : "Any"} 
            onToggle={toggleMatchOption} 
            style={dropdownStyles.matchToggle}
            testID="match_toggle_button"
          />
          <Text style={dropdownStyles.toggleLabel}> tags</Text>
        </View>
      )}
      {isOpen && (
        <View style={dropdownStyles.dropdownContent}>
          {tagsToDisplay
            .slice(0, showAllTags ? tagsToDisplay.length : 4)
            .map(tag => (
              <CustomTouchableOpacity
                key={tag.name}
                onPress={() => {
                  setFilterIncludedTags(includeOption);
                  handleStatusSelect(tag.name);
                }}
                handleStatusSelect={selectedStatus}
                buttonName={tag.name}
                buttonText={tag.text || tag.name}
                orderCount={tag[countProperty] || 0}
                testID={`${tag.name.toLowerCase().replace(/\s+/g, '_')}_button`}
              />
            ))}
            
          {tagsToDisplay.length > 4 && (
            <ShowAllButton
              onPress={toggleShowAllTags}
              showMore={!showAllTags}
              testID="show_all_tags_button"
            />
          )}
        </View>
      )}
    </View>
  );
};

export default OrderTagsDropdown;
