import React, {useState, useRef, useCallback} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  Modal
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import I18n from 'i18n-js';

const InfoTextWithTooltip = ({cartId, screenWidth}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [iconPosition, setIconPosition] = useState({x: 0, y: 0});
  const iconRef = useRef(null);

  const handlePressOutside = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  const handleToggleTooltip = useCallback(() => {
    if (iconRef.current) {
      iconRef.current.measureInWindow((x, y, width, height) => {
        setIconPosition({x, y: y + height});
      });
    }
    setShowTooltip(!showTooltip);
  }, []);

  const getLeft = (value) => {
    if (screenWidth < 768) return value - 150;
    if (screenWidth < 1024) return value - 150;
    return value - 272;
  };

  return (
    <TouchableWithoutFeedback testID='tollTipMainContainer' onPress={handlePressOutside}>
      <View style={styles.infoTextContainer}>
        <Text
          style={[
            styles.infoText,
            {fontSize: screenWidth < 768 ? 12 : 14, display: screenWidth < 900 && 'none'},
          ]}>
          {I18n.t('scanToCart.tollTipLabel', {cartId: cartId})}
        </Text>
        <TouchableOpacity testID='tollTipButton' ref={iconRef} onPress={handleToggleTooltip}>
          <i className='icon-info-circled' style={{fontSize: 20, color: '#ffff'}} />
        </TouchableOpacity>

        <Modal
          transparent
          visible={showTooltip}
          onRequestClose={() => setShowTooltip(false)}>
          <TouchableWithoutFeedback testID='tollTipContainer' onPress={() => setShowTooltip(false)}>
            <View style={styles.modalOverlay}>
              <LinearGradient
                locations={[0, 1]}
                colors={['#8a9daf', '#d7e1e9']}
                style={[
                  styles.tooltipContainer,
                  {
                    position: 'absolute',
                    top: iconPosition.y - 140,
                    left: getLeft(iconPosition.x),
                    width: screenWidth < 768 ? 250 : 300,
                  }
                ]}>
                <Text style={styles.tooltipText}>
                  {I18n.t('scanToCart.tollTip')}
                </Text>
                <View
                  style={[
                    styles.tooltipArrow,
                    {
                      bottom: -10,
                      left: '90%',
                      display: screenWidth < 900 && 'none'
                    }
                  ]}
                />
              </LinearGradient>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  infoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    position: 'relative'
  },
  infoText: {
    fontFamily: 'Poppins_300Light',
    color: '#FFFFFF',
    marginLeft: 10
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'transparent'
  },
  tooltipContainer: {
    padding: 10,
    borderRadius: 5,
    alignSelf: 'flex-start',
    zIndex: 99,
    elevation: 5
  },
  tooltipText: {
    color: '#000000',
    fontSize: 12,
    lineHeight: 18
  },
  tooltipArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderTopWidth: 10,
    borderTopColor: '#d7e1e9'
  }
});

export default InfoTextWithTooltip;
