import React, {useState} from 'react';
import {Text, View, TouchableOpacity, ActivityIndicator} from 'react-native';
import I18n from 'i18n-js';

import {styles} from './styles';
import InfoTextWithTooltip from './infoTextWithTooltip';
import {useDispatch} from 'react-redux';
import {printToteLabels} from '../../actions/scanToCartActions';

const RenderCartHeader = ({name, id, screenWidth, cartId}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handlePrintToteLabel = () => {
    setLoading(true);
    dispatch(printToteLabels(cartId, callBack));
  };

  const callBack = () => {
    setLoading(false);
  };

  return (
    <>
      <View style={styles.cartHeader}>
        <View  style={{width: 150, display: screenWidth < 900 && 'none'}}/>
        <Text
          style={[styles.cartTitle, {fontSize: screenWidth < 768 ? 18 : 20}]}>
          {name}
        </Text>
        {loading ? (
          <>
            <ActivityIndicator />
          </>
        ) : (
          <TouchableOpacity
            style={styles.printButton}
            onPress={handlePrintToteLabel}>
            <i
              className="icon-print"
              style={{fontSize: 24, color: '#ffff'}}></i>
            <Text
              style={[
                styles.printButtonText,
                {fontSize: screenWidth < 768 ? 12 : 14}
              ]}>
              {I18n.t('scanToCart.printToteLabel')}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      <View style={styles.infoContainer}>
        <View style={styles.headerLeft}>
          <Text style={styles.headerText}>
            {I18n.t('scanToCart.rowIdLabel')}
          </Text>
        </View>
        <InfoTextWithTooltip cartId={id} screenWidth={screenWidth} />
        <View style={styles.headerRight}>
          <Text style={styles.headerText}>
            {I18n.t('scanToCart.totesInRowLabel')}
          </Text>
        </View>
      </View>
    </>
  );
};
export default RenderCartHeader;
