import {LinearGradient} from 'expo-linear-gradient';
import i18n from 'i18n-js';
import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Dimensions, Switch, Text, TouchableOpacity, View, Linking,TouchableHighlight, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  GetBothSettings,
  UpdateSettings,
  GetGeneralSetting
} from '../../actions/userAction';
import styles from '../../style/directPrinting';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isHapticsMode } from '../../actions/menuActions';
import ToolTip from './toolTip';
import SettingComponent from './settingComponet';
import PopUpModel from '../scanpack/pop_up_model';
import LanguageToggler from './languageToggler';
import ScanToCartSettings from '../scanToCart/scanToCart';

const Settings = props => {
  const dispatch = useDispatch();
  const [popUpModelShow, setPopUpModelShow] = useState(false);
  const [isLanguageChange, setIsLanguageChange] = useState(false)
  const {bothSettings} = useSelector(state => state.user);
  const {settings} = useSelector(state => state.user);
  const scanPackWorkflow = settings?.data?.scan_pack_workflow;
  const scanToCartOption = bothSettings?.data?.data?.scanpack_setting?.scan_to_cart_option;
  const updateSettingValue = bothSettings?.data?.data?.general_setting;
  const adminSetting = settings?.data?.direct_printing_options;
  const isHaptic = useSelector((state) => state.menu.isHaptics);
  dispatch(isHapticsMode(updateSettingValue?.is_haptics_option))
  const [switchBtn, setSwitchBtn] = useState({
    print_packing_slips: updateSettingValue?.print_packing_slips,
    print_post_scanning_barcodes:
      updateSettingValue?.print_post_scanning_barcodes,
    print_product_barcode_labels:
      updateSettingValue?.print_product_barcode_labels,
    print_ss_shipping_labels: updateSettingValue?.print_ss_shipping_labels,
    print_product_receiving_labels:
      updateSettingValue?.print_product_receiving_labels,
    is_haptics_option:updateSettingValue?.is_haptics_option
  });

  useEffect(() => {
        dispatch(GetBothSettings());
    dispatch(GetGeneralSetting());
  }, []);

  let packingSlipSize =localStorage.getItem('Packing_slip_size') || '{}';
  let ProductBarcodeLabelSize = localStorage.getItem('Product_Barcode_label_size') || '{}';
  const switchToggle = (value, key) => {
    setSwitchBtn({...switchBtn, [key]: value});
    dispatch(UpdateSettings({...switchBtn, [key]: value}));
  };

  const alertBox = () => {
    setPopUpModelShow(true)
    setTimeout(
      function () {
        setPopUpModelShow(false)
      }.bind(this),
      4000
    );
  };

  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );
  const [activeTollTip, setActiveTollTip] = useState('');
  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const handleTollTip = (attribute) => {
    if(activeTollTip !== attribute){
     setActiveTollTip(attribute)
     return
    }
    setActiveTollTip('');
  }

  const checkPrinter = async (key) => {
    return (await AsyncStorage.getItem(key)) || ''
  }
  return (
    <>
    {popUpModelShow && (
        <PopUpModel
            closeAlert={()=>{setPopUpModelShow(false)}}
            message={i18n.t('directprinting.alertForPdfSizeEdit')}
            messageTypeSuccess= {true}
        />
    )}
    <View
      testID="settingContainer"
      style={{height: '100%'}}
      onStartShouldSetResponder={() =>
        setActiveTollTip('')
      }>
      <LinearGradient
        colors={[
          '#000',
          '#000',
          '#5c778f',
          '#5c778f',
          '#253c57',
          '#253c57',
          '#000'
        ]}
        start={[-0.5, -1]}
        style={{
          flex: 1,
          paddingBottom: 15
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}>
          <View
            style={{
              width: windowWidth >= 900 ? '70%' : '372px',
              paddingTop: '58px',
              paddingBottom: '58px',
              marginTop: '43px',
              borderRadius: '25px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <View style={{width: windowWidth >= 900 ? '530px' : '100%'}}>
              <View
                style={{
                  display: 'flex',
                  gap: '10px',
                  left: windowWidth >= 900 ? '6%' : '10%',
                  alignItems: 'center',
                  flexDirection: 'row',
                  paddingBottom: '20px',
                  position: 'relative',
                  zIndex: 7,
                }}>
                <TouchableOpacity
                  testID="tooltipone"
                  onPress={() => handleTollTip('QuickScanToolTip0')}
                  style={{width: 20}}>
                  <i className="icon-info-circled" style={styles.iconInfo}></i>
                </TouchableOpacity>
                <Text style={[styles.subHeading]}>
                  {i18n.t('directprinting.directprintingHead')}
                </Text>
                {activeTollTip === 'QuickScanToolTip0' &&
                  <ToolTip
                    // message={i18n.t('directprinting.tooltipDirectPrintinHeading')}
                    testID={'quicKScanToolTipone'}
                    windowWidth={windowWidth}
                    left={'-52%'}
                    top={8}
                    isLink={true}
                  />
                }
              </View>

              <View
                style={{
                  display: 'flex',
                  gap: '7px',
                  paddingLeft: windowWidth > 900 ? '20px' : '10'
                }}>
                <SettingComponent
                 testId={'tooltiptwo'}
                 isPrinter={checkPrinter(`user_selected_printer_packing_slip_${packingSlipSize}`)}
                 handleTollTip={(value)=>{handleTollTip(value)}}
                 removeItem={`user_selected_printer_packing_slip_${packingSlipSize}`}
                 switchId={'printPackingSlip'}
                 switchDisabled={!adminSetting}
                 switchToggle={(e,value)=>{switchToggle(e,value)}}
                 switchLabel={'print_packing_slips'}
                 switchValue={updateSettingValue?.print_packing_slips}
                 label={i18n.t('directprinting.packingslipSetting')}
                 activeTollTip={activeTollTip}
                 activeTollTipValue={'QuickScanToolTip'}
                 ToolTipId={'quicKScanToolTiptwo'}
                 ToolTipMessage={i18n.t('directprinting.tooltippacking')}
                 left={'-54%'}
                 top={10}
                 width={windowWidth}
                 zIndex={7}
                 type={"packing_slip"}
                 pdfSize={updateSettingValue?.packing_slip_size}
                 handleEdit={() => {alertBox()}}
                />
                <SettingComponent
                 testId={'tooltipsix'}
                 isPrinter={checkPrinter(`print_product_barcode_labels_${ProductBarcodeLabelSize}`)}
                 handleTollTip={(value)=>{handleTollTip(value)}}
                 removeItem={`print_product_barcode_labels_${ProductBarcodeLabelSize}`}
                 switchId={'printProductBarcode'}
                 switchDisabled={!adminSetting}
                 switchToggle={(e,value)=>{switchToggle(e,value)}}
                 switchLabel={'print_product_barcode_labels'}
                 switchValue={updateSettingValue?.print_product_barcode_labels}
                 label={i18n.t('directprinting.ordernumberbarcode')}
                 activeTollTip={activeTollTip}
                 activeTollTipValue={'QuickScanToolTip5'}
                 ToolTipId={'quicKScanToolTipSix'}
                 ToolTipMessage={i18n.t('directprinting.tooltipOrderBarcode')}
                 left={'-54%'}
                 top={15}
                 width={windowWidth}
                 zIndex={6}
                 type={"product_barcode"}
                 pdfSize={settings?.data?.data?.settings.product_barcode_label_size}
                 handleEdit={() => {alertBox()}}
                />
                <SettingComponent
                 testId={'tooltipFour'}
                 isPrinter={checkPrinter('print_ss_shipping_labels')}
                 handleTollTip={(value)=>{handleTollTip(value)}}
                 removeItem={'print_ss_shipping_labels'}
                 switchId={'printSSLabel'}
                 switchDisabled={!adminSetting}
                 switchToggle={(e,value)=>{switchToggle(e,value)}}
                 switchLabel={'print_ss_shipping_labels'}
                 switchValue={updateSettingValue?.print_ss_shipping_labels}
                 label={i18n.t('directprinting.shippiglabel')}
                 activeTollTip={activeTollTip}
                 activeTollTipValue={'QuickScanToolTip3'}
                 ToolTipId={'quicKScanToolTipFour'}
                 ToolTipMessage={i18n.t('directprinting.tooltipShipping')}
                 left={'-54%'}
                 top={17}
                 width={windowWidth}
                 zIndex={5}
                 type={"shipping_label"}
                 handleEdit={() => {alertBox()}}
                 isShipping={true}
                />
                <SettingComponent
                 testId={'tooltipthree'}
                 isPrinter={checkPrinter('user_selected_printer_order_barcode_label')}
                 handleTollTip={(value)=>{handleTollTip(value)}}
                 removeItem={'user_selected_printer_order_barcode_label'}
                 switchId={'printOrderBarcode'}
                 switchDisabled={!adminSetting}
                 switchToggle={(e,value)=>{switchToggle(e,value)}}
                 switchLabel={'print_post_scanning_barcodes'}
                 switchValue={updateSettingValue?.print_post_scanning_barcodes}
                 label={i18n.t('directprinting.productBarcode')}
                 activeTollTip={activeTollTip}
                 activeTollTipValue={'QuickScanToolTipProduct'}
                 ToolTipId={'quicKScanToolTipThree'}
                 ToolTipMessage={i18n.t('directprinting.tooltipBarcode')}
                 left={'-54%'}
                 top={17}
                 width={windowWidth}
                 zIndex={4}
                 type={"order_barcode"}
                 pdfSize={'1 x 3'}
                 handleEdit={() => {alertBox()}}
                />
                <SettingComponent
                 testId={'tooltipfive'}
                 isPrinter={checkPrinter('user_receive_recount_printer')}
                 handleTollTip={(value)=>{handleTollTip(value)}}
                 removeItem={'user_receive_recount_printer'}
                 switchId={'printReceiveRecount'}
                 switchDisabled={!adminSetting}
                 switchToggle={(e,value)=>{switchToggle(e,value)}}
                 switchLabel={'print_product_receiving_labels'}
                 switchValue={updateSettingValue?.print_product_receiving_labels}
                 label={i18n.t('directprinting.receiveRecountLabel')}
                 activeTollTip={activeTollTip}
                 activeTollTipValue={'QuickScanToolTip4'}
                 ToolTipId={'quicKScanToolTipFive'}
                 ToolTipMessage={i18n.t('directprinting.tooltipReceivingPrinting')}
                 left={'-52%'}
                 top={15}
                 width={windowWidth}
                 zIndex={2}
                 type={"receive_recount"}
                 isReceiver={true}
                 handleEdit={() => {alertBox()}}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  gap: '10px',
                  left: '6%',
                  alignItems: 'center',
                  flexDirection: 'row',
                  paddingBottom: '20px',
                  position: 'relative',
                  zIndex: -1,
                  marginTop: 20
                }}>
                <Text style={[styles.subHeading]}>
                  {i18n.t('directprinting.otherOptions')}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  gap: '7px',
                  zIndex: -1,
                  paddingLeft: windowWidth > 900 ? '20px' : '10'
                }}>
                  <View
                  style={{
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                    flexDirection: 'row',
                    position: 'relative',
                  }}>
                  <TouchableOpacity
                    style={{width: 20, zIndex: 99}}
                    testID="tooltipHaptics"
                    onPress={() => handleTollTip('tooltipHaptics')}>
                    <i
                      className="icon-info-circled"
                      style={styles.iconInfo}></i>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="removeHaptics"
                    onPress={() =>
                      switchToggle(false, 'is_haptics_option')
                    }>
                    <LinearGradient
                      colors={['#7BFAC3', '#1CBB77']}
                      locations={[0.1, 0.9]}
                      style={[styles.greenButton]}>
                      <Text>RESET</Text>
                    </LinearGradient>
                  </TouchableOpacity>
                  <Switch
                    testID="hapticsFeedback"
                    offColor="#5c6e79"
                    onColor="##5AA6D1"
                    offHandleColor="#969696"
                    onHandleColor="#fbfdfe"
                    handleDiameter={22}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    value={isHaptic}
                    onValueChange={e =>{
                      switchToggle(e, 'is_haptics_option')
                    }}
                  />
                  <Text
                    style={{color: 'white', fontSize: '16px', fontWeight: 300}}>
                    {i18n.t('directprinting.hapticsLable')}
                  </Text>
                  {activeTollTip === 'tooltipHaptics' &&
                    <ToolTip
                      message={i18n.t('directprinting.tooltipHaptics')}
                      testID={'tooltipHaptics'}
                      windowWidth={windowWidth}
                      left={'-54%'}
                      top={58}
                      bodyTop={-50}
                    />
                  }
                </View>
              </View>
              <LanguageToggler setIsLanguageChange={setIsLanguageChange}/>
            </View>
            {scanPackWorkflow === 'scan_to_cart' && scanToCartOption && <ScanToCartSettings />}
          </View>
        </View>
      </LinearGradient>
    </View>
    </>
  );
};

export default Settings;
