const es = {
  session: {
    to_many_user_login:
      'Hay demasiadas conexiones. Cierra otras sesiones para continuar',
    connectionCheck: 'No se puede contactar con el servidor - Por favor ',
    checkConnection: 'verifique su conexión',
    tryAgain: ' e inténtelo de nuevo.'
  },
  signIn: {
    enter_name: 'Ingrese el nombre en el campo',
    saved_msg: 'Guardado Exitosamente'
  },
  clickScanConfirmation: {
    scan_confirmation_pop_msg:
      'Esta es una nota para el empacador. Se requiere confirmación para esta nota.',
    scan_confirmation_pop_errormsg:
      'Ingrese o escanee su código de confirmación para continuar'
  },
  AddNextBarcode: {
    search_item_msg:
      'Escanee el artículo que desea agregar a este pedido para continuar.',
    confirmation_code_msg:
      'Por favor, escanee o ingrese el código de confirmación de un usuario con permisos para editar pedidos.'
  },
  scanpackItem: {
    shippingEsaySameOrderTextAlert:
      'Los siguientes pedidos comparten un número de orden similar. Toca aquí para cambiar.',
    shipStationSameOrderText:
      'Los siguientes pedidos comparten el mismo número de orden. Toca aquí para cambiar.',
    Scanpack_Item_tracking_msg:
      '¡Vaya! El número de seguimiento que ha escaneado no parece ser válido. Si está seguro de que es correcto, pida a un administrador que verifique la configuración de validación del número de seguimiento en Configuración > Sistema > Escanear y Empacar > Funciones posteriores al escaneo.',
    Scanpack_Item_shipping_msg_two:
      'Para omitir esta verificación, ingrese un código de confirmación con permisos para editar pedidos.',
    Scanpack_type_count_msg:
      'Por favor, escanee el artículo una vez antes de activar el conteo manual.',
    Disable_type_count_msg: 'La función de conteo manual está deshabilitada.',
    Disable_skip_msg: 'La función de omisión está deshabilitada o el código de barras es incorrecto.',
    Scanpack_multipack_msg:
      'El código de barras multipack escaneado excede la cantidad restante.',
    Scan_correct_barocde: 'Por favor, escanee los artículos en el orden sugerido.',
    Scanpack_pass_fun_msg: 'La función de pase está deshabilitada.',
    Scanpack_required_serial_msg:
      "El valor escaneado no parece ser un número de serie o lote válido. Verifique la configuración 'Requerir prefijo de serie/lote' en las opciones de escaneo y empaquetado.",
    Scanpack_barcode_found_or_special_code_msg:
      'El valor ingresado coincide con un código de confirmación de usuario, uno de los códigos de acción o un código de barras de producto. Comuníquese con el soporte si necesita este valor.',
    Scanpack_Item_Shipment: '¡Envío no encontrado!',
    Scanpack_Item_Typecount_exceed_error:
      'La cantidad ingresada excede el número restante de piezas en el pedido. Por favor, inténtelo de nuevo.',
    addBar_text: 'Revise cuidadosamente el artículo antes de escanear.',
    restartAlert:
      '¿Está seguro de que desea restablecer todo el progreso de escaneo para este pedido?',
    service_Issue_msg: 'Pedido con problema de servicio.',
    service_Issue_msg_one:
      'El estado de este pedido es “Problema de servicio”. Para cambiar el estado a “En espera” y poder escanearlo, escanee o ingrese su código de confirmación. Si desea escanear un pedido diferente, puede escanear otro número de pedido para continuar.',
    service_Issue_msg_two:
      'El estado de este pedido es “Problema de servicio”. Para cambiar el estado a “En espera”, pida a un miembro del equipo con permisos para editar pedidos que escanee o ingrese su código de confirmación. Si desea escanear un pedido diferente, puede escanear otro número de pedido para continuar.',
    service_Issue_notification: 'El código de confirmación ingresado no es válido.',
    service_Issue_notification_one:
      'El código de confirmación ingresado no está asociado a un usuario con permisos para editar pedidos.',
    refreshTooltip:
      'El botón de importación iniciará una importación de pedidos desde todas las fuentes activas de la cuenta. Puede verificar el estado de una importación en progreso usando el botón de actualizar.',
    addBarText: 'Se guardará y se marcará como escaneado.',
    trackingSubmit: `El número de seguimiento en la etiqueta de envío que acaba de escanear no coincide con el número de seguimiento importado con ese pedido. Verifique que tiene la etiqueta de envío correcta y vuelva a escanear. Si está al tanto del problema y desea continuar con el siguiente pedido, escanee su código de confirmación para proceder.`,
    moreToScanLabel: 'más por escanear',
    outOfStockLabel: 'Reportar fuera de stock',
    scannedLabel: 'Escaneado',
    typeInCountLabel: 'Conteo manual',
  },
  nextItems: {
    passButtonLabel: 'Aprobar',
    scanAllLabel: 'ESCANEAR\nTODO',
    clickScanLabel: 'Haga clic aquí antes de escanear',
    tabHereLabel: 'Toque aquí antes de escanear',
    readyForScanLabel: 'Listo para escanear el producto',
    gotItLabel: 'Entendido.',
    noOrderToScan: 'No hay pedidos para escanear.',
    noItemsToScan: 'No quedan artículos por escanear.',
    restartButton: {
      firstLabel: 'Reiniciar',
      secondLabel: 'Pedido',
    },
    reloadButton: 'Recargar',
    addNoteButton: {
      firstLabel: 'Agregar',
      secondLabel: 'Nota',
    },
    activityLogLabel: 'Registro de actividades',
    tryAgainLabel: 'Por favor, inténtelo de nuevo.',
    barcodeScanned: 'El código de barras que ha escaneado',
    doesNotMatch: 'no coincide con el número de seguimiento importado con este pedido.',
    addBarCodeLabel: 'AGREGAR UN CÓDIGO DE BARRAS',
    changesSaveLabel: 'Cambios guardados exitosamente',
    youLabel: 'Tú',
    leaderLabel: 'Líder',
    youAreInLead: 'Estás en la delantera ',
    unScannedLabel: 'Artículos no escaneados',
    scannedLabel: 'Artículos escaneados',
    unScannedLabelMobile: 'No escaneado',
    scannedLabelMobile: 'Escaneado',
  },
  popupTracking: {
    noTracingFound: 'No se puede verificar la etiqueta de envío',
    noUser_permission_text_one:
      'Para continuar, puede escanear una etiqueta de envío con un código de seguimiento que coincida con el número de seguimiento asociado con el pedido actual, o puede omitir esta verificación ingresando un código de confirmación con permisos para editar pedidos.',
    noUser_permission_text_two:
      'Si ninguna de estas opciones está disponible, puede cerrar esta notificación y regresar a este pedido más tarde para verificar la etiqueta de envío. El pedido aparecerá en la lista de pendientes hasta que la etiqueta de envío sea verificada.',
    header: 'Por favor, inténtelo de nuevo',
  },
  recordTracingmsg: {
    createShippingLabel:
      'Por favor, cree la etiqueta de envío, adhiérala al paquete y escanee el número de seguimiento para continuar.'
  },
  shippinglabelmsg: {
    scanShippingLabel: 'Por favor, escanee el código de barras de la etiqueta de envío para continuar.',
    noRecordTracking:
      'Este pedido no tiene un número de seguimiento asociado. Puede haberse importado antes de que se generara la etiqueta de envío. Haga clic o presione Enter para omitir esta verificación y marcar el pedido como escaneado.'
  },
  orderConfirmationPopup: {
    orderConfirmationMismatch:
      'Este código de confirmación no parece ser válido. Los códigos de confirmación se pueden actualizar en la página del usuario en la sección de configuración.',
    orderConfirmationText: 'Se requiere confirmación para esta nota:',
    orderConfimationEnterScan:
      'Ingrese o escanee su código de confirmación para continuar.',
    orderConfirmationTextBedoreInput:
      'Por favor, confirme que ha visto la nota ingresando su código de confirmación.'
  },
  productConfirmationPopup: {
    productConfirmationTest:
      'Por favor, confirme que se siguieron las siguientes instrucciones del producto ingresando su código de confirmación.',
    productConfirmationEnterScan:
      'Ingrese o escanee su código de confirmación para continuar',
    productConfirmationAlert:
      'Este código de confirmación no parece ser válido. Los códigos de confirmación se pueden actualizar en la página del usuario en la sección de configuración.',
    inputPlaceHolder: 'Ingrese el código de confirmación para continuar',
    productInstruction: 'Instrucciones del producto para'
  },
  serialRecord: {
    serial_number: 'Escanee o ingrese el número de serie, lote o número de lote',
    serial_number_two: 'Escanee o ingrese el número de serie, lote o número de lote 2'
  },
  scanPack: {
    already_scanned: 'El pedido ya ha sido escaneado.',
    permissionDeniedTitle:  "Permiso denegado",
    permissionDeniedMessage: "Los usuarios administrativos no tienen permiso para escanear pedidos.",
    already_cancelled: 'Este pedido ha sido cancelado y no se puede escanear.',
    scanned_one_pass: 'El pedido ha sido marcado como escaneado.',
    packing_slip: 'Escanee un comprobante de empaque',
    shippingLable_or_packingSlip: 'Escanee un comprobante de empaque o una etiqueta de envío',
    shippingLable: 'Escanee una etiqueta de envío',
    assignOrderTooltip:
      'Este es un ejemplo de texto de ayuda. Puede agregar información adicional aquí para los usuarios.',
    empty_order:
      'No se encontraron artículos en este pedido. Si esto no es lo esperado, intente eliminar y volver a importar el pedido. Si el problema persiste, comuníquese con el soporte.',
    quickScanTollTip: `Haga clic en el botón Escaneo Rápido o escanee un código de barras con el
                      valor QUICKSCAN para mostrar automáticamente el pedido no escaneado más antiguo para su verificación.`,
    noOrderPresent: 'No se encontraron pedidos en la lista de pendientes.',
    alreadyScanned: 'Este pedido ya ha sido escaneado',
    howToProceed: '¿Cómo le gustaría proceder?',
    rePrint: 'Reimprimir etiqueta de envío',
    reScan: 'Reescanear este pedido',
    alreadyScannedMessage: 'El pedido {{orderNumber}} ya ha sido escaneado.',
    inputPlaceHolder: 'Listo para escanear pedido',
    scanLabel: 'Escanear',
    quickScanLabel: 'Escaneo rápido',
    importLabel: 'Importar ahora',
    importStatusLabel: 'Verificar estado de importación',
    rfoTitle: 'Escanee el comprobante de empaque de un pedido que desea empacar.',
    checkInternet: 'Por favor, verifique su conexión a internet',
    importStatus: {
      status: 'Estado de importación',
      last: 'Último',
      current: 'Actual',
      processed: 'Pedidos procesados',
      remaining: 'Pedidos restantes',
      elapsedTime: 'Tiempo transcurrido',
      timeRemaining: 'Tiempo restante',
      lastImport: 'Última importación completada',
    },
    shouldInventoryAdjust: '¿Se debe ajustar el inventario?',
    noReScanItems: 'No, los artículos anteriores se están reescaneando',
    yesPullInventory: 'Sí, se sacaron nuevos artículos del inventario',
    noDataAvailable: 'No hay datos disponibles',
    lastImportLabel: 'La última importación fue',
    importStartedLabel: 'La última importación comenzó',
    ago: 'hace',
    estimatedTime: 'Tiempo estimado restante',
    hoursForStore: 'segundos por tienda',
    minutesForStore: 'minutos por tienda',
    secondsForStore: 'segundos por tienda',
    calculateEstimate: 'Calculando tiempo estimado restante',
    hours: 'horas',
    minutes: 'minutos',
    headerLabel: 'Escanear y Verificar',
  },
  product: {
    images_remove:
      '¿Está seguro de que desea eliminar esta imagen del producto?',
    alias_of_another: 'Hacer que este producto sea un alias de otro',
    aliases_of_this_product: 'Elija productos que sean alias de este producto',
    kit_to_product: 'Cambiar este kit a un producto',
    product_to_kit: 'Cambiar este producto a un kit',
    product_not_found: 'No se puede encontrar el producto con el SKU',
    same_barcode_found: '¡Vaya! Este código de barras ya existe.',
    same_sku_found: '¡Vaya! Este SKU ya existe.',
    QOH: 'Cantidad en Existencia',
    location1: 'Ubicación 1',
    location2: 'Ubicación 2',
    location3: 'Ubicación 3',
    location_qty1: 'Cantidad en Ubicación 1',
    location_qty2: 'Cantidad en Ubicación 2',
    location_qty3: 'Cantidad en Ubicación 3',
    inventory_alert_level: 'Nivel de alerta de inventario',
    inventory_target_level: 'Nivel objetivo de inventario',
    product_name: 'Nombre del producto',
    skus: 'SKUs',
    barcodes: 'Códigos de barras',
    unique_bar: 'Escanee o ingrese un código de barras único',
    unique_sku: 'Escanee o ingrese un SKU único',
    scan_qty: 'Cantidad escaneada',
    categories: 'Categorías',
    fnsku: 'FNSKU',
    asin: 'ASIN',
    isbn: 'ISBN',
    ean: 'EAN',
    supplier_sku: 'SKU del proveedor',
    avg_cost: 'Costo promedio',
    count_group: 'Grupo de conteo',
    product_weight: 'Peso del producto',
    // scan & pack option text
    click_scan: 'Omitir / escaneo por clic',
    type_count: 'Conteo manual',
    record_serial: 'Registrar número de serie / lote',
    intangible_item: 'Artículo intangible',
    add_any_order: 'Agregar a cualquier pedido',
    optional_item: 'Artículo opcional / omitible',
    packing_ins: 'Instrucciones de empaque',
    require_conf: 'Requiere confirmación',
    scanning_seq: 'Anular secuencia de escaneo',
    custom_prod1: 'Producto personalizado 1',
    custom_prod2: 'Producto personalizado 2',
    custom_prod3: 'Producto personalizado 3',
    display: 'Mostrar',
    aliasOfAnotherMessage: '¿Está seguro? ¡Esto no se puede deshacer!',
    click_scan_tooltip:
      'Los artículos con códigos de barras dañados o faltantes pueden ser omitidos usando el botón Omitir si está habilitado. La opción puede deshabilitarse para artículos que no deben omitirse. "Con confirmación" requerirá que un usuario con permiso de omisión proporcione su código de confirmación. "Con mensaje" permitirá la omisión, pero se mostrará un mensaje desalentando su uso.',
    type_count_tooltip:
      'Cuando está habilitado, esta configuración permite escanear un artículo y luego ingresar manualmente una cantidad en lugar de escanear cada artículo uno por uno. Es ideal cuando tiene muchos artículos precontados en cajas. Puede configurar el desencadenante de entrada en la configuración de escaneo y empaque.',
    record_serial_tooltip:
      'Cuando está habilitado, se le pedirá al empacador que escanee un número de serie cada vez que se empaquete este producto. El número de serie registrado se puede ver en el registro de actividad del pedido o descargar en el archivo CSV de series del pedido.',
    intangible_item_tooltip:
      'Los artículos intangibles, también conocidos como artículos transaccionales, son artículos no físicos que no necesitan ser empacados. Ejemplos incluyen códigos de cupones, seguros, cargos por manejo el mismo día, etc.',
    add_any_order_tooltip:
      'Cuando esta opción está habilitada para un producto, puede agregarlo a cualquier pedido que esté empacando solo con escanearlo. Esto es excelente para rastrear el inventario de cajas o bolsas decididas en el momento del empaque.',
    optional_item_tooltip:
      'A diferencia de la opción "Omitir", esta configuración se habilita solo para artículos no esenciales como obsequios o cupones, los cuales pueden omitirse si no hay existencias.',
    packing_ins_tooltip:
      'El texto de las instrucciones de empaque se mostrará cada vez que se sugiera este artículo para escanear. Habilitar la opción de confirmación requerida solicitará al usuario que escanee o ingrese su código de confirmación.',
    scanning_seq_tooltip:
      'La secuencia sugerida de escaneo se determina automáticamente por la ubicación principal del artículo o SKU. Puede anular esta secuencia configurando valores personalizados.',
    custom_prod1_tooltip:
      'Los datos personalizados del producto se pueden ingresar aquí o importar usando el archivo CSV. Esta información se puede mostrar cuando el artículo es sugerido para escanear.',
    scanRecordCountText:
      'Se requiere confirmación para esta acción.\nPor favor, escanee o ingrese su código de confirmación para continuar.',
    scanRecordErrorText:
      'El código de confirmación proporcionado no es válido. Inténtelo de nuevo.',
    zeroBarcodeText:
      'Los artículos "Nuevos" cambiarán automáticamente a "Activos" cuando se agregue su código de barras. No es posible cambiar el estado manualmente.',
    address_new_items: 'Cómo manejar los artículos "Nuevos"',
    // Kit options text
    kit_scanning: 'Opciones de escaneo de kits',
    alertBoxMultiBox:
      'Por favor, escanee los artículos en la caja actual antes de crear otra caja.',
    alertBoxPermission:
      'Por favor, comuníquese con un miembro del equipo y solicite permisos de edición antes de realizar cambios.',
    alertBoxSaveData: 'Los datos del producto se han actualizado',
    status: 'Estado',
    prePackedKitItem: 'Los kits preempacados se escanean como un solo artículo',
    oneBarcodeKitItem: `- Se escanea un solo código de barras que representa todos los artículos del kit.`,
    kitParts: 'Los artículos del kit se escanean individualmente',
    singleKitItem: `- Los códigos de barras de los artículos individuales del kit siempre se escanean.`,
    singleKitBarcode: `- Se escanea un único código de barras del kit, a menos que no haya kits preempacados.`,
    kitPrePacked: `Los kits suelen estar preempacados.`,
    pickerSelectedOptions: {
      one: `1 - Los kits preempacados se escanean como un solo artículo`,
      two: `2 - Los artículos individuales del kit se escanean individualmente`,
      three: `3 - Los kits suelen estar preempacados`
    }
  },
  directprinting: {
    selectprinter:
      'Por favor, elija la impresora que desea usar para trabajos de este tipo.',
    directprintingHead: 'Configuración de impresión directa',
    otherOptions: 'Otras opciones',
    packingslipSetting: 'Comprobantes de empaque y trabajos de impresión',
    productBarcode: 'Código de barras del número de pedido',
    shippiglabel: 'Etiquetas de envío mediante API',
    ordernumberbarcode: 'Etiquetas de código de barras del producto',
    hapticsLable: 'Retroalimentación háptica',
    receiveRecountLabel: 'Etiquetas de recepción de productos',
    alertForPdfSizeEdit:
      'La entrada no es editable en GPX. Puede editar el tamaño del PDF desde la aplicación anterior.',
    packingsliptolltipOne:
      'La impresión directa utiliza una aplicación instalada en su Mac o PC para enviar trabajos de impresión directamente a su impresora en lugar de abrirlos en una pestaña del navegador.',
    packingsliptolltipLinkText: 'Este artículo de la base de conocimientos',
    printerSizeSelectionText:
      'Por favor, seleccione el tamaño de impresión y luego elija un archivo.',
    packingsliptolltipTwo:
      ' lo explica en detalle. Cada usuario puede configurar una impresora para cada tipo de trabajo que se utilizará al imprimir. El botón Restablecer se puede usar para borrar todas las asociaciones de impresoras.',
    productbarcodetolltip:
      'Las etiquetas de código de barras del producto se pueden imprimir desde varias áreas en la aplicación GPX. Cuando está habilitado, se enviarán directamente a la impresora.',
    shippinglabeltolltip:
      'GroovePacker puede generar etiquetas de envío usando la API de etiquetas de algunos gestores de pedidos. Cuando está habilitado, estas etiquetas se enviarán directamente a la impresora.',
    ordernumbertolltip:
      'Una etiqueta pequeña que contiene solo el nombre del cliente y el número de pedido se puede imprimir automáticamente después de escanear cada pedido. Cuando está habilitado, esta etiqueta se enviará directamente a la impresora.',
    tooltippacking:
      'Los comprobantes de empaque se pueden imprimir manualmente o automáticamente. Habilite este interruptor para enviar los comprobantes directamente a la impresora.',
    tooltipBarcode:
      'Las etiquetas de código de barras del producto se pueden imprimir desde varias áreas en la aplicación GPX. Cuando está habilitado, se enviarán directamente a la impresora.',
    tooltipShipping:
      'GroovePacker puede generar etiquetas de envío usando la API de etiquetas de algunos gestores de pedidos. Cuando está habilitado, estas etiquetas se enviarán directamente a la impresora.',
    tooltipOrderBarcode:
      'Una etiqueta pequeña que contiene solo el nombre del cliente y el número de pedido se puede imprimir automáticamente después de escanear cada pedido. Cuando está habilitado, esta etiqueta se enviará directamente a la impresora.',
    tooltipHaptics:
      'Cuando está habilitado, los dispositivos Android vibrarán para confirmar los escaneos y algunas operaciones.',
    tooltipDirectPrintinHeading:
      'La impresión directa utiliza una aplicación instalada en su Mac o PC para enviar trabajos de impresión directamente a su impresora en lugar de abrirlos en una pestaña del navegador. Este artículo de la base de conocimientos lo explica en detalle. Cada usuario puede configurar una impresora para cada tipo de trabajo. El botón Restablecer se puede usar para borrar todas las asociaciones de impresoras.',
    tooltipReceivingPrinting:
      'Las etiquetas de recepción de productos se utilizan para etiquetar cajas de inventario adicional para que puedan identificarse y escanearse fácilmente. Este interruptor habilita la opción de impresión directa para estas etiquetas.',
    tooltipWeights:
      'Puede ingresar pesos en unidades métricas o inglesas. Para el sistema inglés, el peso se puede ingresar usando una combinación de libras y onzas o solo onzas. Para el sistema métrico, el peso se puede ingresar usando una combinación de kilogramos y gramos o solo gramos.',
    tooltipResidential:
      'UPS requiere que un envío sea designado como Residencial si se envía a una dirección residencial o Comercial si se envía a una dirección comercial.',
    alertForPdfLimit:
      'Ha alcanzado el límite diario de selecciones de archivos o el tamaño del archivo supera los 40 MB.',
    alertForNoPrinter:
      'No hay impresoras disponibles. Para imprimir desde el navegador, desactive la impresión directa.',
    confirmAgainPrinting:
      '¿Está seguro? \n Parece que este archivo ya pudo haberse impreso.',
    pdfMovedNotification:
      'Después de imprimir, el PDF se moverá a la pestaña de impresos.',
    // printComplete: "Archivo impreso usando QZ"
    selectLanguage: 'Seleccionar idioma',
    english: 'Inglés',
    spanish: 'Español',
  },
  clearFilterPrompt: {
    clearFiltersText: 'Ningún pedido en la vista actual coincide con su búsqueda.',
    clearFiltersBtnText:
      'Haga clic aquí para borrar los filtros y expandir su búsqueda a todos los pedidos en GroovePacker.'
  },
  packingCamDetail: {
    enterEmail:
      'Por favor, agregue una dirección de correo electrónico a continuación para habilitar las opciones de la cámara de empaque.',
    placeholder: 'Ingrese dirección de correo electrónico',
    emailSentPopup: 'Correo electrónico enviado con éxito',
    failToUpload: 'Error al subir la imagen, inténtelo de nuevo',
    uploadComplete: 'Carga completada',
    uploading: 'Cargando...',
    escTollTip: 'ESC para cancelar',
    spaceBarTollTip: 'BARRA ESPACIADORA para volver a tomar',
    enterTollTip: 'ENTER para confirmar',
    cancelLabel: 'Cancelar',
    retakeLabel: 'Volver a tomar',
    confirmLabel: 'Confirmar',
  },
  reprintText: {
    reprintNotAllowHeading:
      'Fueron compradas en el gestor de pedidos y deben ser reimpresas allí.',
    previousPurchaseLabelHeading: 'Etiquetas previamente compradas encontradas',
    canBeReprintHeading: 'Las etiquetas impresas anteriormente pueden ser reimpresas a continuación',
    canBeReprintSubHead: 'Haga clic en una etiqueta para reimprimirla.'
  },
  order: {
    internal_notes: 'Notas internas',
    notes_to_packer: 'Notas para el empacador',
    customer_comments: 'Comentarios del cliente',
    tags: 'Etiquetas',
    notes_from_packer: 'Notas del empacador',
    internal_notes_tooltip:
      'Las notas internas se pueden importar desde la fuente del pedido. Por ejemplo, si agrega una nota a un pedido en ShipStation o ShippingEasy, se puede importar y guardar en este campo. La importación de notas internas se controla en la configuración de cada tienda. Una vez importadas, una opción en la configuración de escaneo y empaque determina si la nota se mostrará al empacador.',
    notes_to_packer_tooltip:
      'Si las notas para el empacador se agregan directamente en GroovePacker, este campo debe usarse. Todo lo que se guarde aquí siempre se mostrará durante el escaneo.',
    customer_comments_tooltip:
      'Con algunas integraciones, los comentarios del cliente se pueden importar a este campo y mostrarse opcionalmente durante el escaneo. Una vez importados, una opción en la configuración de escaneo y empaque determina si los comentarios del cliente se mostrarán al empacador.',
    tags_tooltip:
      'Las etiquetas de pedido de Shopify se pueden importar a este campo y mostrarse opcionalmente durante el escaneo. Una vez importadas, una opción en la configuración de escaneo y empaque determina si las etiquetas se mostrarán al empacador.',
    notes_from_packer_tooltip:
      'Una nota agregada al pedido durante el proceso de escaneo se mostrará aquí. La nota también se puede enviar automáticamente por correo electrónico a la dirección de notificaciones especificada en la configuración del sistema. La nota se puede agregar durante el escaneo usando el botón "Agregar nota" o escaneando el código de acción: NOTA. Este código de barras se puede imprimir desde la sección de configuración de Escanear y Empacar.',
    order_number: 'Número de pedido',
    scanning_completed: 'Escaneo completado',
    order_date: 'Fecha del pedido',
    tracking_number: 'Número de seguimiento',
    customer_order_data1: 'Datos del pedido del cliente 1',
    customer_order_data2: 'Datos del pedido del cliente 2',
    first_name: 'Nombre',
    last_name: 'Apellido',
    email: 'Correo electrónico',
    company: 'Empresa',
    city: 'Ciudad',
    state: 'Estado',
    address_1: 'Dirección 1',
    address_2: 'Dirección 2',
    postal_code: 'Código postal',
    country: 'País',
    addKitToKitMessage:
      '¿Le gustaría agregar los artículos de kit del kit {{childKit}} como artículos del kit {{parentKit}}?',
    kitItemTolltip:
      'Busque los artículos del kit que desea agregar ingresando el nombre, el SKU o escaneando el código de barras. Haga clic en un artículo para seleccionarlo. Se pueden realizar múltiples búsquedas y selecciones antes de guardar para agregar varios artículos al mismo tiempo.',
    inventoryKitTollTip:
      'Los kits preempacados se escanean como un solo artículo - Se escanea un código de barras que representa todos los artículos del kit. Esto permite el seguimiento del inventario de las partes del kit. Los artículos individuales del kit se escanean uno por uno - Los códigos de barras de los artículos individuales siempre se escanean. Los kits suelen estar preempacados - Se escanea un único código de barras del kit a menos que no haya kits preempacados.',
    productSearchListTooltip:
      'Escanee o ingrese el código de barras de un artículo para abrirlo directamente. La búsqueda con el SKU exacto también abrirá el artículo directamente. Las coincidencias parciales se mostrarán en una lista.',
    addItemToOrderError:
      'Por favor, cambie el estado de este producto/kit a Activo antes de agregarlo al pedido.',
    addItemToOrderTollTip:
      'Se pueden agregar varios artículos a un pedido al mismo tiempo. Después de buscar un artículo por su nombre, SKU o código de barras, use la casilla de verificación para seleccionarlo. Se pueden realizar selecciones adicionales de la misma manera. Una vez seleccionados todos los artículos necesarios, haga clic en "Guardar y cerrar" para agregarlos al pedido.',
    multiBoxTollTip:
      'El menú desplegable le permite seleccionar si el comprobante de empaque se imprimirá para la caja seleccionada actualmente (en la tabla a continuación) o si se imprimirán los comprobantes de empaque para todas las cajas. Haga clic en el ícono de la impresora para iniciar la impresión.',
    orderNotFound:
      'El número de pedido proporcionado no se encontró. Es posible que el pedido correspondiente aún no se haya importado.',
    selectedUserError:
      'Por favor, seleccione múltiples pedidos para asignar o eliminar.',
    noSelectedUserError:
      'Por favor, seleccione pedidos o usuarios para asignar o eliminar.',
    clearAllAssignmentLabel: 'Borrar todas las asignaciones',
    clearAllAssignmentMessage: '¿Está seguro de que desea borrar todas las asignaciones?',
  },
  tagPriority: {
    createPriorityCardText:
      'Los pedidos con esta prioridad se identificarán con la siguiente etiqueta:'
  },
  activityException: {
    alerts: {
      exceptionRecorded: 'Excepción registrada exitosamente',
      exceptionMissingReason: 'No se puede registrar la excepción sin una razón',
      exceptionCleared: 'Registro de excepción eliminado',
      noExceptionToClear: 'El pedido no tiene ninguna excepción que eliminar'
    },
    headers: {
      activityLog: 'Registro de actividad',
      packingExceptions: 'Excepciones de empaque',
      exceptionType: 'Tipo de excepción',
      description: 'Descripción',
      associatedWith: 'Asociado con la excepción'
    },
    buttons: {
      saveAsText: 'Guardar como texto',
      recordException: 'Registrar excepción',
      clearException: 'Eliminar excepción'
    }
  },
  addItemInBox: {
    addItemsTollTip: `Se pueden agregar varios artículos a un pedido al mismo tiempo. Después de buscar
              un artículo por su nombre, SKU o código de barras, use la casilla de
              verificación para seleccionarlo. Se pueden realizar selecciones adicionales de la misma manera. Una vez
              que se seleccionen todos los artículos necesarios, haga clic en "Guardar y cerrar" para agregarlos
              al pedido.`
  },
  shipmentRecord: {
    additionalShipments: 'Envíos adicionales asociados con este pedido',
    shipmentWarning:
      'Uno de los envíos asociados con este pedido fue escaneado en GroovePacker y luego combinado en ShippingEasy. En lugar de modificar un pedido que ya ha sido escaneado, le informamos para que pueda tomar las acciones necesarias.',
    acknowledgeAndContinue:
      'Una vez que se eliminen todos los envíos no deseados, puede hacer clic aquí para confirmar y continuar.'
  },
  scanToCart: {
    tollTip: `Si los pedidos necesitan asignarse a un tote específico, se pueden etiquetar con el ID de tote correspondiente. Por ejemplo, etiquetar un pedido con "A-1-C01" lo asignará al primer tote de la fila A del carrito 1. Haga clic en un tote a continuación para copiar su etiqueta de ID de tote al portapapeles.`,
    tollTipLabel: `Los pedidos etiquetados como "{{cartId}}" solo se asignarán a este carrito.`,
    addRowLabel: `Agregar una Fila`,
    addCartLabel: `Agregar un Carrito`,
    cartsLabel: 'Carritos',
    printToteLabel: 'Imprimir Etiquetas de Totes',
    rowIdLabel: 'ID de Fila',
    totesInRowLabel: 'Totes/Fila',
    saveChanges: 'Guardar cambios..',
  },
  assignOrder: {
    assign: 'Asignar',
    orders: 'Órdenes',
    successMessage: 'Órdenes asignadas con éxito.',
    notAssignedMessage: 'No se asignaron órdenes.',
  },
};
export default es;
