import React, {useEffect, useState} from 'react';
import {View, Text, TextInput} from 'react-native';
import {styles} from './styles';
import {TouchableOpacity} from 'react-native';

export default function ToteRowItemsControllers({initialValue = 0, onUpdate}) {
  const [localValue, setLocalValue] = useState(initialValue?.toString());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      setLocalValue(initialValue.toString());
    }
  }, [initialValue, isEditing]);

  const handleSubmit = () => {
    setIsEditing(false);
    const numValue = parseInt(localValue, 10);
    if (!isNaN(numValue) && numValue >= 1 && numValue <= 10) {
      onUpdate(numValue);
    } else {
      setLocalValue(initialValue.toString());
    }
  };

  const handleChange = text => {
    if (text === '' || (/^\d+$/.test(text) && parseInt(text, 10) <= 10)) {
      setLocalValue(text);
    }
  };

  return (
    <View style={styles.toteCountContainer}>
      <TextInput
        testID="toteCountInput"
        style={styles.toteCountInput}
        value={localValue}
        onChangeText={handleChange}
        onFocus={() => setIsEditing(true)}
        onBlur={handleSubmit}
        onSubmitEditing={handleSubmit}
        keyboardType="numeric"
        maxLength={2}
        returnKeyType="done"
      />
      <View>
        <TouchableOpacity
          testID="addToteButton"
          style={styles.countButton}
          onPress={() => onUpdate(initialValue + 1)}>
          <i
            className="icon-up-open"
            style={{fontSize: 18, color: '#ffff'}}></i>
        </TouchableOpacity>
        <TouchableOpacity
          testID="removeToteButton"
          style={styles.countButton}
          onPress={() => onUpdate(initialValue - 1)}>
          <i
            className="icon-down-open"
            style={{fontSize: 18, color: '#ffff'}}></i>
        </TouchableOpacity>
      </View>
    </View>
  );
}
