import React, { useEffect, useRef } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  StyleSheet
} from 'react-native';
import styles from '../../style/scanpack';
import i18n from 'i18n-js';
import globalStyles from '../../style/global';
import { fontFamily } from '../../helpers/fontFamily';
import '../scanpack/addBar.css';
import { LinearGradient } from 'expo-linear-gradient';
import { useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function ListAlert(props) {
  let windowWidth = window.innerWidth;
  const msg = !props.msg ? 'Add a Note' : props.msg;

  const [selectedItem, setSelectedItem] = useState(null); // Store the selected item
  const STORAGE_KEY = '@MyFlatList:selectedItem';

  // Load the selected item from storage when the component mounts
  useEffect(() => {
    AsyncStorage.getItem(STORAGE_KEY).then((value) => {
      if (value) {
        setSelectedItem(JSON.parse(value));
      }
    });
  }, []);

  useEffect(() => {
    if (selectedItem) {
      AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(selectedItem));
    }
  }, [selectedItem]);

  return (
    <View style={[styles.responseView]}
    >
      <View style={[windowWidth >= 900 ? styles.typeScanContainer : "", windowWidth <= 900 ? styles.recordSerialMobile : "",{marginBottom: '9%'}]}>
        <LinearGradient
          locations={[0, 1]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
          colors={['#d47d02', '#ffb636']}
          style={[
            styles.typeScanBox,
            {
              backgroundColor: '#d3dfea',
              paddingHorizontal: 10,
              paddingVertical: 5,
              width: windowWidth >= 900 ? '50%' : '100%',
              marginTop: "2%",
              borderColor: '#e8971a',
              borderWidth: 3,
              height: 'fit-content',
              boxShadow: '2px 2px 10px #363636'
            }
          ]}
        >
          <TouchableOpacity onPress={props?.handleModelClose}>
            <i
              className="icon-cancel"
              style={{color: 'black',fontSize: 22, marginLeft: 'auto', }}></i>
          </TouchableOpacity>
          <View
            style={
              ([globalStyles.flexDirectionRow],
                { marginTop: 10, alignItems: 'center' })
            }
          >
           <Text
              testID='messageText'
              style={[
                styles.scanCountText,
                { fontFamily: fontFamily.font400, fontSize: 20,textAlign: 'justify',width: '90%' }
              ]}
            >
            {msg}
            </Text>

            <View style={alertStyles.responseView}
            >
            
          </View>

          </View>
          <View
            style={alertStyles.sizeContainer}
          >
              <View style={[alertStyles.sizeContainerSecond,{width: windowWidth < 900 ? 180 : 242}]}>
                <LinearGradient
                  style={{borderRadius: 10, backgroundColor: 'linear-gradient(180deg, #2D445F 0%, #2D445F 100%)'}}
                  colors={['#2D445F', '#2D445F']}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                >
                <View style={[alertStyles.listContainer,{width: windowWidth < 900 ? 180 : 242}]}>
                  <View style={{width: '100%'}}>
                  <FlatList
                  data={props.listData}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item }) => (
                      
                      <TouchableOpacity onPress={() => setSelectedItem(item.value)} testID='selectSizeBtn'>
                        <View
                          style={[alertStyles.labelContainer,
                            {backgroundColor: item.value === selectedItem ? 'rgba(255, 255, 255, 0.15)' : ''}]
                          }
                      >
                        <Text style={
                          alertStyles.labelTextStyle
                        }>
                         {item.label}
                        </Text>
                      </View>
                      </TouchableOpacity>
                  )}
                  
                />
                </View>

                </View>
                </LinearGradient>
                
              </View>
            {props.loading && <View style={{flex: 1, justifyContent: 'center'}}>
              <ActivityIndicator size="large" color="#336599" />
            </View> }

            {
            <LinearGradient
            style={{borderRadius: 15}}
            start={{x: 0, y: 1}}
            end={{x: 0, y: 0}}
            colors={[
              'rgba(255, 255, 255, 0.04)','rgba(255, 255, 255, 0.25)'
            ]}>
            <View style={alertStyles.chooseFileStyle}>
                
                  <TouchableOpacity onPress={props.pickDocument} testID='choosePdfBtn'
                  style={
                    [styles.modalButton, {width: 87, border: 'none', marginBottom: 0}]
                  }>
                      <Text>
                          Choose File
                      </Text>
                  </TouchableOpacity>
              </View>
            </LinearGradient>
            }

          </View>
          <View style={alertStyles.responseView}>
          </View>
        </LinearGradient>
      </View>
    </View>
  );
}

const alertStyles = StyleSheet.create({
  responseView: {
    flex: 1,
    height: 39,
    paddingVertical: 12, 
    paddingHorizontal: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 8,
    flexShrink: 0,
    alignSelf: 'stretch'
  },
  sizeContainer: {
    display: 'flex',
    height: 200,
    paddingTop: 12,
    paddingRight: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    flexShrink: 0,
    alignSelf: 'stretch',
  },
  sizeContainerSecond: {
    display: 'flex',
    height: 143,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 10, // For gap, use marginVertical
    marginBottom: 21,
    flexShrink: 0,
  },
  listContainer: {
    flex: 1,
    height: 143,
    paddingVertical: 13,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6, 
    flexShrink: 0,
    borderRadius: 10,
  },
  labelContainer: {
    flex: 1,
    gap: 18,
    paddingTop: 2,
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  labelTextStyle: {
    color: '#FFF', 
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 18,
    fontStyle: 'normal',
    lineHeight: 22,
  },
  chooseFileStyle:{
    flexDirection: 'row',
    paddingVertical: 1, // For gap, use paddingVertical
    paddingHorizontal: 14,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    border: '1px solid #000'
  }
});
